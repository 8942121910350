<template>
  <div>
    <div class="page-title">方片库存管理</div>
    <!-- 选项 -->
    <div style="float:left;margin-top: 1rem;">
      <el-col :span="24">
        <el-tooltip content="波段" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.BoDuan" clearable placeholder="-波段-" style="width:120px" @change="BoDuanChg">
            <el-option
              v-for="item in BoDuanList"
              :key="item.label"
              :value="item.label"
              :label="item.label"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="型号" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.XingHao" clearable placeholder="-型号-" style="width:130px">
            <el-option
              v-for="item in XingHaoList"
              :key="item"
              :value="item"
              :label="item"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="状态信息" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.ZhuangTai" clearable placeholder="-状态信息-" style="width: 120px">
            <el-option
              v-for="item in ZhuangTaiList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="来源" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.LaiYuan" clearable placeholder="-来源-" style="width: 120px">
            <el-option
              v-for="item in LaiYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="来源厂家" placement="top" transition="none" style="width: 160px" effect="light">
          <el-select
            v-model="colScreen.LYCJ"
            remote
            reserve-keyword
            :remote-method="getSource_"
            filterable
            clearable
            default-first-option
            placeholder="来源厂家"
          >
            <el-option
              v-for="item in LYCJList"
              :key="item.来源厂家"
              :label="item.来源厂家"
              :value="item.来源厂家">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="最新动作" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.ZXDongZuo" clearable placeholder="-最新动作-" style="width: 120px">
            <el-option
              v-for="item in ZXDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="出库动作" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.CKDongZuo" clearable placeholder="-出库动作-" style="width: 120px">
            <el-option
              v-for="item in CKDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="业务员" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.YeWuYuan" clearable placeholder="-业务员-" style="width: 120px">
            <el-option
              v-for="item in YeWuYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-tooltip>
      </el-col>
    </div>
    <!-- 日期及搜索 -->
    <div style="float:left;margin-top: 1rem;width: 100%">
      <el-col :span="24">
        <el-tooltip content="入库时间" placement="bottom" transition="none" effect="light" style="width: 160px">
          <el-date-picker
            v-model="colScreen.RuKuDate"
            type="daterange"
            placeholder="入库时间"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="送货单日期" placement="bottom" transition="none" effect="light" style="width: 160px">
          <el-date-picker
            v-model="colScreen.ShdDate"
            type="daterange"
            placeholder="送货单日期"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="发货时间" placement="bottom" transition="none" effect="light" style="width: 160px">
          <el-date-picker
            v-model="colScreen.FhDate"
            type="daterange"
            placeholder="发货时间"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="搜索项" placement="bottom" transition="none" effect="light" style="width: 150px">
          <el-select v-model="colScreen.SX" clearable placeholder="-搜索项-">
            <el-option
              v-for="item in SXOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="搜索关键词" placement="bottom" transition="none" effect="light" style="width: 230px">
          <el-input clearable placeholder="请输入关键词" v-model="colScreen.keywords"
                    @keydown.enter.native="startSearch"></el-input>
        </el-tooltip>
        <el-button type="primary" plain @click="startSearch">开始筛选</el-button>
      </el-col>
    </div>
    <!-- 按钮 -->
    <div style="float:left;width: 100%;margin-top: 1rem">
      <el-button size="small" type="danger" plain @click="ShowInLayer">导入方片</el-button>
      <el-button size="small" type="success" plain @click="ClickOutput">导出方片</el-button>
      <el-button size="small" type="danger" plain @click="ShowRewrite">覆盖导入方片</el-button>
      <el-button size="small" type="primary" plain @click="ShowBatLayer">导入方片批量修改</el-button>
      <el-button size="small" type="primary" plain @click="ShowBSLayer(false)">批量修改选中方片</el-button>
      <el-button size="small" type="success" plain @click="changeAll">全选本页</el-button>
      <el-button size="small" type="danger" @click="showDelSKULayer(false)">删除所选</el-button>
      <el-button size="small" type="danger" plain @click="ClearSelected">取消已选</el-button>
      <el-button size="small" type="warning" plain @click="ClearAll">清空选项</el-button>
    </div>
    <!-- Qty 合计 -->
    <div style="width: 100%;float:left;margin-top: 1rem">根据筛选条件可得 Qty 合计数量为：{{ sumQty }}</div>
    <!-- 表格 -->
    <div style="margin-top: 1rem; float:left; width: 100%;">
      <pl-table
        use-virtual
        class="tb-edit"
        :data="SquareData"
        border
        @row-click="rowSelect"
        @row-contextmenu="rightClick"
        @row-dblclick="dbClick"
        @cell-mouse-enter="holdLeft"
        ref="multipleTable"
        :row-class-name="tableRowClassName"
        height="490"
        :row-height="28"
        :cell-style="{padding:0,height:'28px'}"
        :header-cell-style="{padding:0,height:'30px'}"
      >
        <pl-table-column prop="状态信息" label="状态信息" width="120" :fixed="fixedCol==='状态信息'">
          <template slot="header">
            状态信息 <span @click="fixedCol='状态信息'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="来源" label="来源" width="120" :fixed="fixedCol==='来源'">
          <template slot="header">
            来源 <span @click="fixedCol='来源'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="来源厂家" label="来源厂家" width="120" :fixed="fixedCol==='来源厂家'">
          <template slot="header">
            来源厂家 <span @click="fixedCol='来源厂家'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="最新动作" label="最新动作" width="100" :fixed="fixedCol==='最新动作'">
          <template slot="header">
            最新动作 <span @click="fixedCol='最新动作'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="出库动作" label="出库动作" width="100" :fixed="fixedCol==='出库动作'">
          <template slot="header">
            出库动作 <span @click="fixedCol='出库动作'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="备注" label="备注" width="200"></pl-table-column>
        <pl-table-column prop="发货时间" label="发货时间" width="110" :fixed="fixedCol==='发货时间'">
          <template slot="header">
            发货时间 <span @click="fixedCol='发货时间'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="客户" label="客户" width="120" :fixed="fixedCol==='客户'">
          <template slot="header">
            客户 <span @click="fixedCol='客户'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="业务员" label="业务员" width="100" :fixed="fixedCol==='业务员'">
          <template slot="header">
            业务员 <span @click="fixedCol='业务员'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="波段" label="波段" width="120" :fixed="fixedCol==='波段'">
          <template slot="header">
            波段 <span @click="fixedCol='波段'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="型号" label="型号" width="120" :fixed="fixedCol==='型号'">
          <template slot="header">
            型号 <span @click="fixedCol='型号'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="入库时间" label="入库时间" width="110" :fixed="fixedCol==='入库时间'">
          <template slot="header">
            入库时间 <span @click="fixedCol='入库时间'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="送货单日期" label="送货单日期" width="110" :fixed="fixedCol==='送货单日期'">
          <template slot="header">
            送货单日期 <span @click="fixedCol='送货单日期'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="Wafer_ID" label="Wafer ID" width="200" :fixed="fixedCol==='Wafer_ID'">
          <template slot="header">
            Wafer ID <span @click="fixedCol='Wafer_ID'"><i class="el-icon-s-flag"></i></span>
          </template>
        </pl-table-column>
        <pl-table-column prop="Bin_Value" label="Bin Value" width="135">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="Bin Value" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.Bin_Value"
                placeholder="Bin Value"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('Bin_Value')"
                size="mini"
              >
                <!--<el-option v-for="item in VF1" :value="item" :label="item"></el-option>-->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="VF1_MIN" label="VF1 MIN" width="135">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="VF1 MIN" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.VF1_MIN"
                placeholder="VF1 MIN"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('VF1_MIN')"
                size="mini"
              >
                <el-option v-for="item in defaultOptions.VF1" :value="item" :label="item"></el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="VF1_AVG" label="VF1 AVG" width="135">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="VF1 AVG" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.VF1_AVG"
                placeholder="VF1 AVG"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('VF1_AVG')"
                size="mini"
              >
                <el-option v-for="item in defaultOptions.VF1" :value="item" :label="item"></el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="VF1_MAX" label="VF1 MAX" width="135">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="VF1 MAX" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.VF1_MAX"
                placeholder="VF1 MAX"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('VF1_MAX')"
                size="mini"
              >
                <el-option v-for="item in defaultOptions.VF1" :value="item" :label="item"></el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="VF1_STD" label="VF1 STD" width="135">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="VF1 STD" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.VF1_STD"
                placeholder="VF1 STD"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('VF1_STD')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="LOP1_MIN" label="LOP1 MIN" width="140">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="LOP1 MIN" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.LOP1_MIN"
                placeholder="LOP1 MIN"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('LOP1_MIN')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="LOP1_AVG" label="LOP1 AVG" width="140">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="LOP1 AVG" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.LOP1_AVG"
                placeholder="LOP1 AVG"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('LOP1_AVG')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="LOP1_MAX" label="LOP1 MAX" width="140">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="LOP1 MAX" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.LOP1_MAX"
                placeholder="LOP1 MAX"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('LOP1_MAX')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="LOP1_STD" label="LOP1 STD" width="140">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="LOP1 STD" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.LOP1_STD"
                placeholder="LOP1 STD"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('LOP1_STD')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP1_MIN" label="WLP1 MIN" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLP1 MIN" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLP1_MIN"
                placeholder="WLP1 MIN"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLP1_MIN')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP1_AVG" label="WLP1 AVG'" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLP1 AVG" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLP1_AVG"
                placeholder="WLP1 AVG"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLP1_AVG')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP1_MAX" label="WLP1 MAX" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLP1 MAX" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLP1_MAX"
                placeholder="WLP1 MAX"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLP1_MAX')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP1_STD" label="WLP1 STD" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLP1 STD" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLP1_STD"
                placeholder="WLP1 STD"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLP1_STD')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <!-- ################ -->
        <pl-table-column prop="WLD1_MIN" label="WLD1 MIN" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLD1 MIN" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLD1_MIN"
                placeholder="WLD1 MIN"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLD1_MIN')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLD1_AVG" label="WLD1 AVG" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLD1 AVG" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLD1_AVG"
                placeholder="WLD1 AVG"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLD1_AVG')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLD1_MAX" label="WLD1 MAX" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLD1 MAX" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLD1_MAX"
                placeholder="WLD1 MAX"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLD1_MAX')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLD1_STD" label="WLD1 STD" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="WLD1 STD" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.WLD1_STD"
                placeholder="WLD1 STD"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('WLD1_STD')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="IR_MIN" label="IR MIN" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="IR MIN" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.IR_MIN"
                placeholder="IR MIN"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('IR_MIN')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="IR_AVG" label="IR AVG" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="IR AVG" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.IR_AVG"
                placeholder="IR AVG"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('IR_AVG')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="IR_MAX" label="IR MAX" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="IR MAX" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.IR_MAX"
                placeholder="IR MAX"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('IR_MAX')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="IR_STD" label="IR STD" width="145">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="IR STD" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.IR_STD"
                placeholder="IR STD"
                filterable
                allow-create
                clearable
                default-first-option
                @change="selectColChange('IR_STD')"
                size="mini"
              >
                <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <!-- ################ -->
        <pl-table-column prop="Qty" label="Qty" width="105">
          <template slot="header" slot-scope="scope">
            <el-tooltip content="Qty" placement="top" effect="light" transition="none">
              <el-select
                v-model="colScreen.Qty"
                placeholder="Qty"
                clearable
                filterable
                allow-create
                default-first-option
                @change="selectColChange('Qty')"
                size="mini"
              >
                <el-option value="asc" label="Qty ↑"></el-option>
                <el-option value="desc" label="Qty ↓"></el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="SERIAL" label="SERIAL" width="150"></pl-table-column>
      </pl-table>
    </div>
    <!-- 分页 -->
    <div style="width: 100%;float:left;margin-top: 1rem;">
      <el-pagination
        @current-change="handlePageChange"
        :current-page="whichPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 右键菜单 -->
    <div id="contextmenu" v-show="menuVisible" class="menu">
      <div class="contextmenu__item" @click="EditData">修改</div>
      <div class="contextmenu__item" @click="ShowLogs">操作日志</div>
      <div class="contextmenu__item" @click="Cancel">取消</div>
    </div>
    <!-- 导入层 -->
    <el-dialog
      title="导入方片库存"
      :visible.sync="inLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="uploadUrl"
        :on-success="function(res){return uploadOver(res)}"
        :before-upload="function(file){ return beforeUpload(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step01"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <div v-show="step02">
        <square-table my-name="insTable" ref="insTable" :table-data="InsConfirm" @change-screen="getScreen"/>
      </div>
      <div style="width: 100%;float:left;margin:0.5rem 0" v-show="step02">
        <el-pagination
          @current-change="insPageChg"
          :current-page="insWP"
          :page-size="insPS"
          layout="total, prev, pager, next, jumper"
          :total="insCnt">
        </el-pagination>
      </div>
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step02">已缓存 {{ insCnt }} 条记录（Qty {{ batQty }}）</span>
        <el-button type="primary" v-show="step02" @click="DoIns" plain>确认导入</el-button>
        <el-button @click="CancelIns" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 覆盖导入层 -->
    <el-dialog
      title="覆盖导入方片库存"
      :visible.sync="rwLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <span
        v-show="rwStep1"
        style="font-size:1.2rem;font-weight:520;padding-bottom:1rem;float:left;width: 100%"
      >请上传包含覆盖数据的表格文件</span>
      <el-upload
        ref="rwUpload"
        class="upload-demo"
        drag
        :action="rwUploadUrl"
        :on-success="function(res){return rwUploaded(res)}"
        :before-upload="function(file){ return rwBeforeUp(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="rwStep1"
        style="margin-left:30px"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <!-- 覆盖前确认数据表 -->
      <div v-show="rwStep2">
        <square-table my-name="rwTable" ref="rwTable" :table-data="rwConfirm" @change-screen="getScreen"/>
      </div>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="rwStep2">共 {{ rwConfirm.length }} 条记录（Qty {{ batQty }}）</span>
        <el-button type="warning" v-show="rwStep2" @click="rwChgON" plain style="margin-left:9rem">
          切换为{{ rwConfirm === rwOldData ? '新' : '旧' }}数据
        </el-button>
        <el-button type="danger" v-show="rwStep2" @click="rwDo" plain>确定覆盖</el-button>
        <el-button @click="rwCancel" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量修改层 -->
    <el-dialog
      title="批量修改方片库存"
      :visible.sync="batLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <span
        v-show="step1"
        style="font-size:1.2rem;font-weight:520;padding-bottom:1rem;float:left;width: 100%"
      >请上传批量修改的表格数据文件</span>
      <el-upload
        ref="uploadBat"
        class="upload-demo"
        drag
        :action="uploadBatUrl"
        :on-success="function(res){return uploadBatOver(res)}"
        :before-upload="function(file){ return beforeUpload(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
        style="margin-left:30px"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <!-- 批量修改确认数据表 -->
      <div v-show="step2">
        <square-table my-name="batTable" ref="batTable" :table-data="batConfirm" @change-screen="getScreen"/>
      </div>
      <div style="width: 100%;float:left;margin:0.5rem 0" v-show="step2">
        <el-pagination
          @current-change="batPageChg"
          :current-page="batWP"
          :page-size="batPS"
          layout="total, prev, pager, next, jumper"
          :total="batCnt">
        </el-pagination>
      </div>
      <!-- 批量修改表单 -->
      <el-form :model="batFormData" v-show="step3">
        <span
          style="float:left;width:100%;font-size:1.2rem;font-weight:520;padding-bottom: 1rem;">请填写批量修改的信息</span>
        <span style="float:left;width:100%;margin-bottom:1rem;">
          小提示：仅填写需要修改的项目信息，不需要修改的项目留白即可。
        </span>
        <el-form-item label="状态信息" label-width="120px">
          <el-select v-model="batFormData.状态信息" clearable placeholder="-状态信息-" style="width: 120px">
            <el-option
              v-for="item in ZhuangTaiList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源" label-width="120px">
          <el-select v-model="batFormData.来源" clearable placeholder="-来源-" style="width: 120px">
            <el-option
              v-for="item in LaiYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源厂家" label-width="120px">
          <el-select
            v-model="batFormData.来源厂家"
            remote
            reserve-keyword
            :remote-method="getSource_"
            filterable
            clearable
            allow-create
            default-first-option
            placeholder="来源厂家"
          >
            <el-option
              v-for="item in LYCJList"
              :key="item.来源厂家"
              :label="item.来源厂家"
              :value="item.来源厂家">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最新动作" label-width="120px">
          <el-select v-model="batFormData.最新动作" clearable placeholder="-最新动作-" style="width: 120px">
            <el-option
              v-for="item in ZXDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库动作" label-width="120px">
          <el-select v-model="batFormData.出库动作" clearable placeholder="-出库动作-" style="width: 120px">
            <el-option
              v-for="item in CKDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务员" label-width="120px">
          <el-select v-model="batFormData.业务员" clearable placeholder="-业务员-" style="width: 120px">
            <el-option
              v-for="item in YeWuYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库时间" label-width="120px">
          <el-date-picker
            v-model="batFormData.入库时间"
            type="date"
            placeholder="入库时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="送货单日期" label-width="120px">
          <el-date-picker
            v-model="batFormData.送货单日期"
            type="date"
            placeholder="送货单日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发货时间" label-width="120px">
          <el-date-picker
            v-model="batFormData.发货时间"
            type="date"
            placeholder="发货时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户" label-width="120px">
          <el-select
            v-model="batFormData.客户"
            remote
            reserve-keyword
            :remote-method="getCustom_"
            filterable
            clearable
            allow-create
            default-first-option
            placeholder="客户"
          >
            <el-option
              v-for="item in KeHuList"
              :key="item.公司"
              :label="item.公司"
              :value="item.公司">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="追加备注" label-width="120px">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="追加备注信息"
            v-model="batFormData.备注">
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step2">共找到 {{ batCnt }} 条记录（Qty {{ batQty }}）</span>
        <el-button type="primary" v-show="step2" @click="BatNext" plain>下一步</el-button>
        <el-button type="danger" v-show="step3" @click="DoBat" plain style="margin-left:9rem">批量修改</el-button>
        <el-button @click="CancelBat" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 单条修改层 -->
    <el-dialog
      title="修改方片信息"
      :visible.sync="editLayer"
      width="700px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-form :model="whichRightData">
        <el-form-item label="Wafer ID" label-width="120px">
          <el-input v-model="whichRightData.Wafer_ID" readonly disabled></el-input>
        </el-form-item>
        <!-- 增加波长及 Qty 修改功能 -->
        <el-form-item
          label="WLP1 MIN"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLP1_MIN"></el-input>
        </el-form-item>
        <el-form-item
          label="WLP1 AVG"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLP1_AVG"></el-input>
        </el-form-item>
        <el-form-item
          label="WLP1 MAX"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLP1_MAX"></el-input>
        </el-form-item>
        <el-form-item
          label="WLP1 STD"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLP1_STD"></el-input>
        </el-form-item>
        <!-- #############  -->
        <el-form-item
          label="WLD1 MIN"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLD1_MIN"></el-input>
        </el-form-item>
        <el-form-item
          label="WLD1 AVG"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLD1_AVG"></el-input>
        </el-form-item>
        <el-form-item
          label="WLD1 MAX"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLD1_MAX"></el-input>
        </el-form-item>
        <el-form-item
          label="WLD1 STD"
          label-width="120px"
        >
          <el-input v-model="whichRightData.WLD1_STD"></el-input>
        </el-form-item>
        <!-- ############### -->
        <el-form-item label="Qty" label-width="120px">
          <el-input v-model="whichRightData.Qty"></el-input>
        </el-form-item>
        <!-- end -->
        <el-form-item label="状态信息" label-width="120px">
          <el-select v-model="whichRightData.状态信息" clearable placeholder="-状态信息-" style="width: 120px">
            <el-option
              v-for="item in ZhuangTaiList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源" label-width="120px">
          <el-select v-model="whichRightData.来源" clearable placeholder="-来源-" style="width: 120px">
            <el-option
              v-for="item in LaiYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源厂家" label-width="120px">
          <el-select
            v-model="whichRightData.来源厂家"
            remote
            reserve-keyword
            :remote-method="getSource_"
            filterable
            clearable
            allow-create
            default-first-option
            placeholder="来源厂家"
          >
            <el-option
              v-for="item in LYCJList"
              :key="item.来源厂家"
              :label="item.来源厂家"
              :value="item.来源厂家">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最新动作" label-width="120px">
          <el-select v-model="whichRightData.最新动作" clearable placeholder="-最新动作-" style="width: 120px">
            <el-option
              v-for="item in ZXDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库动作" label-width="120px">
          <el-select v-model="whichRightData.出库动作" clearable placeholder="-出库动作-" style="width: 120px">
            <el-option
              v-for="item in CKDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务员" label-width="120px">
          <el-select v-model="whichRightData.业务员" clearable placeholder="-业务员-" style="width: 120px">
            <el-option
              v-for="item in YeWuYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库时间" label-width="120px">
          <el-date-picker
            v-model="whichRightData.入库时间"
            type="date"
            placeholder="入库时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="送货单日期" label-width="120px">
          <el-date-picker
            v-model="whichRightData.送货单日期"
            type="date"
            placeholder="送货单日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发货时间" label-width="120px">
          <el-date-picker
            v-model="whichRightData.发货时间"
            type="date"
            placeholder="发货时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户" label-width="120px">
          <el-select
            v-model="whichRightData.客户"
            remote
            reserve-keyword
            :remote-method="getCustom_"
            filterable
            clearable
            allow-create
            default-first-option
            placeholder="客户"
          >
            <el-option
              v-for="item in KeHuList"
              :key="item.公司"
              :label="item.公司"
              :value="item.公司">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原备注" label-width="120px">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="原备注"
            readonly
            disabled
            v-model="whichRightData.备注">
          </el-input>
        </el-form-item>
        <el-form-item label="追加备注" label-width="120px">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="追加备注信息"
            v-model="whichRightData.追加备注">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="DoSingle" plain>确认修改</el-button>
        <el-button @click="editLayer = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 日志层 -->
    <el-dialog
      :title="'Wafer ID '+whichRightData.Wafer_ID+' 的操作日志'"
      :visible.sync="logsLayer"
      width="720px"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-timeline>
        <el-timeline-item v-for="item in whichLogs" :timestamp="item['操作时间']" placement="top">
          <el-card>
            <strong>操作事项</strong>
            <p v-html="item.操作事项"></p>
            <strong>操作内容</strong>
            <p v-html="item.操作内容.replace(/\n/g,'<br>')"></p>
            <strong v-if="item.备注">备注</strong>
            <p v-if="item.备注" v-html="item.备注.replace(/\n/g,'<br>')"></p>
            <strong>操作人</strong>
            <p v-html="item.操作人"></p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logsLayer = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 批量选择层 -->
    <el-dialog
      title="批量修改选中的方片"
      :visible.sync="BSLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 批量修改选中的确认数据表 -->
      <div v-show="step11">
        <square-table my-name="bsTable" ref="bsTable" :table-data="BSConfirm" @change-screen="getScreen"/>
      </div>
      <!-- 批量修改选中 表单 -->
      <el-form :model="BSFormData" v-show="step12">
        <span
          style="float:left;width:100%;font-size:1.2rem;font-weight:520;padding-bottom: 1rem;">请填写批量修改的信息</span>
        <span style="float:left;width:100%;margin-bottom:1rem;">
          小提示：仅填写需要修改的项目信息，不需要修改的项目留白即可。
        </span>
        <el-form-item label="状态信息" label-width="120px">
          <el-select v-model="BSFormData.状态信息" clearable placeholder="-状态信息-" style="width: 120px">
            <el-option
              v-for="item in ZhuangTaiList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源" label-width="120px">
          <el-select v-model="BSFormData.来源" clearable placeholder="-来源-" style="width: 120px">
            <el-option
              v-for="item in LaiYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源厂家" label-width="120px">
          <el-select
            v-model="BSFormData.来源厂家"
            remote
            reserve-keyword
            :remote-method="getSource_"
            filterable
            clearable
            allow-create
            default-first-option
            placeholder="来源厂家"
          >
            <el-option
              v-for="item in LYCJList"
              :key="item.来源厂家"
              :label="item.来源厂家"
              :value="item.来源厂家">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最新动作" label-width="120px">
          <el-select v-model="BSFormData.最新动作" clearable placeholder="-最新动作-" style="width: 120px">
            <el-option
              v-for="item in ZXDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库动作" label-width="120px">
          <el-select v-model="BSFormData.出库动作" clearable placeholder="-出库动作-" style="width: 120px">
            <el-option
              v-for="item in CKDongZuoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务员" label-width="120px">
          <el-select v-model="BSFormData.业务员" clearable placeholder="-业务员-" style="width: 120px">
            <el-option
              v-for="item in YeWuYuanList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库时间" label-width="120px">
          <el-date-picker
            v-model="BSFormData.入库时间"
            type="date"
            placeholder="入库时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="送货单日期" label-width="120px">
          <el-date-picker
            v-model="BSFormData.送货单日期"
            type="date"
            placeholder="送货单日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发货时间" label-width="120px">
          <el-date-picker
            v-model="BSFormData.发货时间"
            type="date"
            placeholder="发货时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户" label-width="120px">
          <el-select
            v-model="BSFormData.客户"
            remote
            reserve-keyword
            :remote-method="getCustom_"
            filterable
            clearable
            allow-create
            default-first-option
            placeholder="客户"
          >
            <el-option
              v-for="item in KeHuList"
              :key="item.公司"
              :label="item.公司"
              :value="item.公司">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="追加备注" label-width="120px">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="追加备注信息"
            v-model="BSFormData.备注">
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step11">共选中 {{ BSConfirm.length }} 条记录（Qty {{ batQty }}）</span>
        <el-button type="primary" v-show="step11" @click="BSNext" plain>下一步</el-button>
        <el-button type="danger" v-show="step12" @click="DoBS" plain style="margin-left:9rem">批量修改</el-button>
        <el-button @click="CancelBS" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量删除层 -->
    <el-dialog
      title="批量删除选中的方片"
      :visible.sync="DelLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 批量删除 选中的确认数据表 -->
      <div>
        <square-table my-name="delTable" ref="delTable" :table-data="BSConfirm" @change-screen="getScreen"/>
      </div>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem">共选中 {{ BSConfirm.length }} 条记录（Qty {{ batQty }}）</span>
        <el-button type="danger" @click="delSquareSKU_" style="margin-left:3rem">确认删除</el-button>
        <el-button @click="DelLayer=false" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Loading} from 'element-ui'
import {formRequest} from '../http/formRequest'
import {
  delSquareBatFile,
  getSquareSKU,
  batEditSquare,
  singleEditSquare,
  getSquareLogs,
  getCustom,
  getSourceList,
  getBSSquare,
  editBSSquare,
  insSquareSKU,
  clSquareSKU,
  getCacheSquare,
  getBatData,
  delSquareSKU, rewriteSquare, rewriteSquareCl,
} from '../http/api'
import moment from 'moment'
import {
  baseUrl,
  baseTitle,
  BoDuanList,
  ZhuangTaiList,
  LaiYuanList,
  ZXDongZuoList,
  CKDongZuoList,
  defaultOptions, YeWuYuanList,
} from '../configs/squareConfig'
import SquareTable from "../components/SquareTable";

let loading
export default {
  name: "ProdSquareSku",
  components: {SquareTable},
  data() {
    return {
      fixedCol: 'Wafer_ID',
      SquareData: [],
      token: this.$cookies.get('token'),
      menuVisible: false,
      BoDuanList: BoDuanList,
      XingHaoList: [],
      ZhuangTaiList: ZhuangTaiList,
      LaiYuanList: LaiYuanList,
      ZXDongZuoList: ZXDongZuoList,
      CKDongZuoList: CKDongZuoList,
      YeWuYuanList: YeWuYuanList,
      LYCJList: [],
      KeHuList: [],
      SXOptions: ['Wafer ID', '型号', '来源厂家', '客户', '备注'],
      colScreen: {},
      // **********
      inLayer: false,
      outLayer: false,
      batLayer: false,
      editLayer: false,
      logsLayer: false,
      DelLayer: false,
      uploadUrl: baseUrl + '/square-sku-upload',
      uploadBatUrl: baseUrl + '/square-bat-upload',
      count: 0,
      pageSize: 500,
      whichPage: 1,
      sumQty: 0,
      batFormData: {},
      whichRightData: {},
      whichLogs: [],
      step1: true,
      step2: false,
      step3: false,
      batConfirm: [],
      squareSelected: [],
      BSLayer: false,
      step11: true,
      step12: false,
      BSConfirm: [],
      BSFormData: {},
      batQty: 0,
      defaultOptions: defaultOptions,
      step01: true,
      step02: false,
      InsConfirm: [],
      InsFormData: {},
      insWP: 1,
      insPS: 500,
      insCnt: 0,
      batWP: 1,
      batPS: 500,
      batCnt: 0,
      subScreen: {},
      rwLayer: false,
      rwStep1: true,
      rwStep2: false,
      rwUploadUrl: baseUrl + '/square-rw-upload',
      rwConfirm: [],
      rwFormData: {},
      rwNewData: [],
      rwOldData: [],
    }
  },
  methods: {
    // 波段被切换时
    BoDuanChg() {
      const b = this.colScreen.BoDuan
      this.XingHaoList = []
      delete this.colScreen.XingHao
      if (b) {
        const f = this.BoDuanList.find(item => item.label === b)
        this.XingHaoList = f.value
      }
    },
    // 点击开始筛选
    startSearch() {
      this.whichPage = 1
      this.getSquareSKU_()
    },
    // 获取 方片库存数据
    getSquareSKU_() {
      const token = this.token
      const colScreen = this.colScreen
      const page = this.whichPage - 1
      getSquareSKU({token, colScreen, page})
        .then(res => {
          let rs = res.data.rs
          rs.forEach((item, index) => {
            if (item.入库时间) rs[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
            if (item.送货单日期) rs[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
            if (item.发货时间) rs[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
          })
          this.SquareData = rs
          this.whichPage = res.data.page + 1
          this.pageSize = res.data.pageSize
          this.count = res.data.count
          this.sumQty = res.data.sumQty
        })
        .catch(err => console.log(err))
    },
    // 获取 客户列表
    getCustom_(query) {
      const token = this.token
      const keywords = query
      getCustom({
        token,
        keywords,
      })
        .then(res => {
          this.KeHuList = []
          this.KeHuList = res.data.rs
        })
        .catch(err => console.log(err))
    },
    // 获取 来源厂家
    getSource_(query) {
      const token = this.token
      const keywords = query
      getSourceList({token, keywords})
        .then(res => {
          this.LYCJList = []
          this.LYCJList = res.data.rs
        })
        .catch(err => console.log(err))
    },
    // 主表格 行双击事件
    dbClick(row) {
      this.whichRightData = JSON.parse(JSON.stringify(row))
      this.editLayer = true
    },
    // 主表格 行右击事件
    rightClick(row, column, event) {
      this.whichRightData = JSON.parse(JSON.stringify(row))
      this.menuVisible = false  // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true   // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault()    //关闭浏览器右键默认事件
      const menu = document.querySelector('.menu');
      this.styleMenu(menu)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
    },
    // 主表格 右键菜单样式
    styleMenu(menu) {
      let e = event || window.event
      let X = e.clientX
      let Y = e.clientY
      if (X > 1800) {
        menu.style.left = X - 100 + 'px'
      } else {
        menu.style.left = X + 1 + 'px'
      }
      document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      if (Y > 700) {
        menu.style.top = Y - 30 + 'px'
      } else {
        menu.style.top = Y - 10 + 'px'
      }
    },
    // 主表格 右键菜单取消
    Cancel() {
      console.log('取消')
    },
    // 主表格 全选本页
    changeAll() {
      this.SquareData.forEach(item => {
        const chk = this.squareSelected.findIndex(s => s.id === item.id)
        if (chk === -1) {
          this.squareSelected.push({
            id: item.id,
            Wafer_ID: item.Wafer_ID,
          })
        }
      })
    },
    // 主表格 点击行设置选中或者取消状态
    rowSelect(row) {
      let checked = this.squareSelected
      let index = checked.findIndex(item => item.id === row.id)
      if (index === -1) {
        this.squareSelected.push({
          id: row.id,
          Wafer_ID: row.Wafer_ID,
        })
      } else {
        this.squareSelected.splice(index, 1)
      }
      this.$refs.multipleTable.setCurrentRow(-1)
    },
    // 清空已选择的行
    ClearSelected() {
      this.squareSelected = []
      // this.$refs.multipleTable.clearSelection()
    },
    // 主表格 选项被选择
    selectColChange(which) {
      this.whichPage = 1
      this.getSquareSKU_()
    },
    // 主表格 拖拽选择
    holdLeft(row, column, cell, event) {
      // if (event.which === 1) {
      //   console.log(row)
      //   this.$refs.multipleTable.toggleRowSelection(row);
      // }
    },
    // 单条信息 修改弹窗
    EditData() {
      this.editLayer = true
    },
    // 单条信息 执行修改
    DoSingle() {
      const token = this.token
      const singleForm = this.whichRightData
      singleEditSquare({token, singleForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.editLayer = false
            this.getSquareSKU_()
            this.$message({
              type: 'success',
              message: res.data.msg,
            })
          }
        })
        .catch(err => console.log(err))
    },
    // 新数据导入 显示上传弹窗
    ShowInLayer() {
      this.inLayer = true
      this.subScreen = {}
      if (this.$refs.insTable) this.$refs.insTable.colScreen = {}
      if (this.$refs.upload) this.$refs.upload.clearFiles()
    },
    // 新数据导入 上传成功前
    beforeUpload(file) {
      loading = Loading.service({
        fullscreen: true
      })
    },
    // 新数据导入 上传成功后
    uploadOver(res) {
      loading.close()
      this.$refs.upload.clearFiles()
      if (res.code === 'OK') {
        this.$notify({
          title: res.title,
          type: 'success',
          message: res.msg,
          // duration: 0,
        })
        let rs = res.rs
        rs.forEach((item, index) => {
          rs[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
          rs[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
          if (item.发货时间) rs[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
        })
        this.step01 = false
        this.step02 = true
        this.InsConfirm = rs
        this.clearSubScreen('insTable')
        this.batQty = res.sumQty
        this.InsFormData = {
          filePath: res.filePath,
          fullPath: res.fullPath,
          uploadTime: res.uploadTime,
        }
        this.insCnt = res.count
        this.insWP = res.page + 1
        this.insPS = res.pageSize
      } else {
        this.$notify({
          title: res.title,
          type: 'error',
          message: res.msg,
          // duration: 0,
        })
      }
    },
    // 新数据导入 表格翻页
    insPageChg(e) {
      this.insWP = e
      const page = e - 1
      const token = this.token
      getCacheSquare({token, page, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            let rs = res.data.rs
            rs.forEach((item, index) => {
              rs[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
              rs[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
              if (item.发货时间) rs[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
            })
            this.InsConfirm = rs
            this.insWP = res.data.page + 1
            this.batQty = res.data.sumQty
            this.insCnt = res.data.count
          }
        })
        .catch(err => console.log(err))
    },
    // 新数据导入 从缓存区执行写入
    DoIns() {
      const token = this.token
      const filePath = this.InsFormData.filePath
      insSquareSKU({token, filePath, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '导入成功'
            })
          }
          this.InsFormData = {}
          this.InsConfirm = []
          this.step01 = true
          this.inLayer = this.step02 = false
          this.getSquareSKU_()
        })
        .catch(err => console.log(err))
    },
    // 新数据导入 取消操作
    CancelIns() {
      const token = this.token
      const insForm = this.InsFormData
      clSquareSKU({token, insForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.inLayer = this.step02 = false
            this.step01 = true
          }
        })
        .catch(err => console.log(err))
    },
    // 覆盖导入 弹窗
    ShowRewrite() {
      this.rwLayer = true
      this.subScreen = {}
      if (this.$refs.rwUpload) this.$refs.rwUpload.clearFiles()
    },
    // 覆盖导入 上传前
    rwBeforeUp() {
      loading = Loading.service({
        fullscreen: true
      })
    },
    // 覆盖导入 上传成功后
    rwUploaded(res) {
      loading.close()
      this.$refs.rwUpload.clearFiles()
      if (res.code === 'OK') {
        this.$notify({
          type: 'success',
          message: res.msg,
          // duration: 0,
        })
        let rs = res.rs
        rs.forEach((item, index) => {
          rs[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
          rs[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
          if (item.发货时间) rs[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
        })

        let rw = res.rw
        rw.forEach((item, index) => {
          rw[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
          rw[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
          if (item.发货时间) rw[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
        })

        rs.sort((a, b) => {
          if (a.Wafer_ID > b.Wafer_ID) return 1
          if (a.Wafer_ID < b.Wafer_ID) return -1
          if (a.Wafer_ID === b.Wafer_ID) return 0
        })

        rw.sort((a, b) => {
          if (a.Wafer_ID > b.Wafer_ID) return 1
          if (a.Wafer_ID < b.Wafer_ID) return -1
          if (a.Wafer_ID === b.Wafer_ID) return 0
        })
        this.rwOldData = rs
        this.rwNewData = this.rwConfirm = rw
        this.rwStep1 = false
        this.rwStep2 = true
        this.clearSubScreen('rwTable')
        this.batQty = res.sumQty
        this.rwFormData = {
          filePath: res.filePath,
          fullPath: res.fullPath,
          uploadTime: res.uploadTime,
        }
      } else {
        this.$notify({
          title: '上传失败',
          type: 'error',
          message: res.msg,
          // duration: 0,
        })
      }
    },
    // 覆盖导入 切换新旧数据
    rwChgON() {
      if (this.rwConfirm === this.rwNewData) this.rwConfirm = this.rwOldData
      else this.rwConfirm = this.rwNewData
      this.batQty = 0
      this.rwConfirm.forEach(item => this.batQty += item.Qty)
    },
    // 覆盖导入 执行覆盖
    rwDo() {
      const token = this.token
      const rwFormData = this.rwFormData
      rewriteSquare({token, rwFormData})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '覆盖成功'
            })
          }
          this.rwFormData = {}
          this.rwConfirm = this.rwNewData = this.rwOldData = []
          this.rwStep1 = true
          this.rwLayer = this.rwStep2 = false
          this.getSquareSKU_()
        })
        .catch(err => console.log(err))
    },
    // 覆盖导入 取消操作
    rwCancel() {
      const token = this.token
      const rwFormData = this.rwFormData
      rewriteSquareCl({token, rwFormData})
        .then(res => {
          if (res.data.code === 'OK') {
            this.rwFormData = {}
            this.rwConfirm = this.rwNewData = this.rwOldData = []
            this.rwStep1 = true
            this.rwLayer = this.rwStep2 = false
          }
        })
        .catch(err => console.log(err))
    },
    // 批量修改 文件导入弹窗
    ShowBatLayer() {
      this.batLayer = true
      this.subScreen = {}
      if (this.$refs.batTable) this.$refs.batTable.colScreen = {}
      if (this.$refs.uploadBat) this.$refs.uploadBat.clearFiles()
    },
    // 批量修改 上传成功后
    uploadBatOver(res) {
      this.$refs.uploadBat.clearFiles()
      loading.close()
      if (res.code !== 'OK') {
        this.$notify({
          title: '上传失败',
          type: 'error',
          message: res.msg,
        })
      } else {
        this.$notify({
          type: "success",
          message: '上传成功',
        })
        let rsBat = res.rsBat
        rsBat.forEach((item, index) => {
          rsBat[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
          rsBat[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
          if (item.发货时间) rsBat[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
        })
        this.batConfirm = rsBat
        this.clearSubScreen('batTable')
        this.batQty = res.sumQty
        this.batWP = res.page + 1
        this.batPS = res.pageSize
        this.batCnt = res.count
        this.batFormData.完整路径 = res.fullPath
        this.batFormData.文件路径 = res.filePath
        this.batFormData.上传时间 = res.uploadTime
        this.step2 = true
        this.step1 = this.step3 = false
      }
    },
    // 批量修改 翻页
    batPageChg(e) {
      this.batWP = e
      const page = e - 1
      const token = this.token
      const fullPath = this.batFormData.完整路径
      getBatData({token, page, fullPath, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            let rsBat = res.data.rsBat
            rsBat.forEach((item, index) => {
              rsBat[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
              rsBat[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
              if (item.发货时间) rsBat[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
            })
            this.batConfirm = rsBat
            this.batWP = res.data.page + 1
            this.batQty = res.data.sumQty
            this.batCnt = res.data.count
          }
        })
        .catch(err => console.log(err))
    },
    // 批量修改 点下一步
    BatNext() {
      this.step3 = true
      this.step1 = this.step2 = false
    },
    // 批量修改 执行
    DoBat() {
      const token = this.token
      const batFormData = this.batFormData
      batEditSquare({token, batFormData, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            this.batFormData = {}
            this.step1 = true
            this.step2 = this.step3 = false
            this.batLayer = false
            this.getSquareSKU_()
            this.$notify({
              title: res.data.title,
              message: res.data.msg,
              type: 'success',
              // duration: 0,
            })
            formRequest(baseUrl + '/square-ue-output', {
              token: this.token,
              exFile: res.data.exFile,
              customer: res.data.customer,
            })
          }
        })
        .catch(err => console.log(err))
    },
    // 批量修改 取消
    CancelBat() {
      const token = this.token
      const 完整路径 = this.batFormData.完整路径
      const 文件路径 = this.batFormData.文件路径
      const 上传时间 = this.batFormData.上传时间
      if (完整路径 && 文件路径 && 上传时间) {
        delSquareBatFile({token, batFormData: this.batFormData})
          .then(res => console.log(res))
          .catch(err => console.log(err))
      }
      this.batFormData = {}
      this.step1 = true
      this.batLayer = this.step2 = this.step3 = false
    },
    // 批量选中  结果弹窗
    ShowBSLayer(save) {
      if (!save) this.clearSubScreen('bsTable')
      const token = this.token
      const ids = this.squareSelected
      let batQty = this.batQty = 0
      getBSSquare({token, ids, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            let rsBS = res.data.rsBS
            rsBS.forEach((item, index) => {
              batQty += item.Qty
              rsBS[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
              rsBS[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
              if (item.发货时间) rsBS[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
            })
            this.BSConfirm = rsBS
            this.batQty = batQty
            this.BSLayer = true
          }
        })
        .catch(err => console.log(err))
    },
    // 批量选中 下一步
    BSNext() {
      this.step11 = false
      this.step12 = true
    },
    // 批量选中 执行修改
    DoBS() {
      const token = this.token
      const ids = this.squareSelected
      const BSFormData = this.BSFormData
      editBSSquare({token, ids, BSFormData, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$notify({
              type: 'success',
              message: res.data.msg
            })
            this.getSquareSKU_()
            this.BSFormData = {}
            this.step11 = true
            this.BSLayer = this.step12 = false
            this.squareSelected = []
          }
        })
        .catch(err => console.log(err))
    },
    // 批量选中 取消操作
    CancelBS() {
      this.step11 = true
      this.BSLayer = this.step12 = false
    },
    // 读取并显示日志弹窗
    ShowLogs() {
      const token = this.token
      const Wafer_ID = this.whichRightData.Wafer_ID
      getSquareLogs({token, Wafer_ID})
        .then(res => {
          if (res.data.code === 'OK') {
            let rs = res.data.rs
            rs.forEach((item, index) => rs[index].操作时间 = moment(new Date(item.操作时间)).format('YYYY/MM/DD HH:mm:ss'))
            this.whichLogs = rs
          }
          this.logsLayer = true
        })
        .catch(err => console.log(err))
    },
    // 点击导出
    ClickOutput() {
      this.$confirm('确定要导出符合当前条件的方片数据吗？')
        .then(res => {
          const token = this.token
          const colScreen = JSON.stringify(this.colScreen)
          formRequest(baseUrl + '/square-output', {token, colScreen})
        })
        .catch(err => console.log(err))
    },
    // 清空所有筛选项
    ClearAll() {
      this.colScreen = {}
    },
    // 翻页事件
    handlePageChange(e) {
      this.whichPage = e
      this.getSquareSKU_()
    },
    // 删除前确认
    showDelSKULayer(save) {
      if (!save) this.clearSubScreen('delTable')
      const token = this.token
      const ids = this.squareSelected
      let batQty = this.batQty = 0
      getBSSquare({token, ids, colScreen: this.subScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            let rsBS = res.data.rsBS
            rsBS.forEach((item, index) => {
              batQty += item.Qty
              rsBS[index].入库时间 = moment(new Date(item.入库时间)).format('YYYY/MM/DD')
              rsBS[index].送货单日期 = moment(new Date(item.送货单日期)).format('YYYY/MM/DD')
              if (item.发货时间) rsBS[index].发货时间 = moment(new Date(item.发货时间)).format('YYYY/MM/DD')
            })
            this.BSConfirm = rsBS
            this.batQty = batQty
            this.DelLayer = true
          }
        })
        .catch(err => console.log(err))
    },
    // 删除方片库存
    delSquareSKU_() {
      this.$confirm('确认删除这些方片的库存信息吗？请注意，这将同时删除相关联的操作日志！')
        .then(res => {
          const token = this.token
          const ids = this.squareSelected
          delSquareSKU({token, ids, colScreen: this.subScreen})
            .then(res => {
              if (res.data.code === 'OK') {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.DelLayer = false
                this.getSquareSKU_()
                this.squareSelected = []
              }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    // 接子组件发送的筛选项值
    getScreen(screen) {
      const myName = screen.myName
      if (!myName) {
        alert('错误：未接收到组件名')
        return
      }
      this.subScreen = screen
      //***************************
      if (myName === 'insTable') this.insPageChg(1)
      if (myName === 'batTable') this.batPageChg(1)
      if (myName === 'bsTable') this.ShowBSLayer(true)
      if (myName === 'delTable') this.showDelSKULayer(true)
    },
    // 清空子组件的 screen
    clearSubScreen(subName) {
      this.subScreen = {}
      if (this.$refs[subName]) this.$refs[subName].colScreen = {}
    }
  },
  computed: {
    tableRowClassName() {
      return ({row}) => {
        const id = row.id
        const idx = this.squareSelected.findIndex(row => row.id === id)
        if (idx !== -1) return 'warning-row'
        return ''
      }
    },
  },
  activated() {
    this.getSquareSKU_()
    document.title = '方片库存数据管理 - ' + baseTitle
  },
}
</script>

<style>
.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: fixed;
  background-color: #fff;
  width: 100px;
  /*height: 106px;*/
  font-size: 12px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

p {
  margin: 0;
  padding: 0 0 8px 0;
}

strong, p {
  line-height: 1.3;
}

.el-table .warning-row {
  background: yellow !important;
  color: red !important;
  font-weight: bold;
}
</style>
